#NavBar-logo {
    height: 40px;
    pointer-events: none;
    width: fit-content;
}

#NavBar-desktop {
    pointer-events: none;
    width: fit-content;
}

@media only screen and (min-width: 620px) {
    #NavBar-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1140px;
        margin: 60px auto 0 auto;
        list-style: none;
        transition-duration: 0.2s, 0.2s;
        transition-timing-function: ease, ease;
    }

    #NavBar-logo {
        flex-shrink: 1;
        width: fit-content;
    }

    #NavBar-social {
        list-style: none;
        flex-grow: 2;
        text-align: right;
        display: flex;
        justify-content: flex-end;
    }

    #NavBar-wrapper-scroll {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1140px;
        margin: 20px auto 0 auto;
        transition-duration: 0.2s, 0.2s;
        transition-timing-function: ease, ease;
    }

    #NavBar-list-scroll {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-flow: row-reverse;
        align-self: flex-end;
    }
}

#NavBar-wrapper {
    max-width: 1140px;
    width: auto;
}

#NavBar-wrapper-mobile {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 72px;
    z-index: 99;
    transition: background-color 0.2s, margin 0.2s;
}

#NavBar-wrapper-mobile-open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 90;
    width: 100%;
    height: 100vh;
    background-color: rgba(22, 21, 53, 0.95);
}

#Navbar-logo-mobile {
    height: 30px;
    margin-left: 30px;
    height: 40px;
    pointer-events: none;
    width: fit-content;
    display: flex;
    align-items: center;
}

#Navbar-logo-img-mobile {
    height: 30px;
}

.Navbar-mobile-scroll {
    background: linear-gradient(to bottom, #161535, transparent);
}

#NavBar-inner-mobile {
    display: flex;
    width: 100%;
    margin: auto;
    margin-top: 35px;
    justify-content: space-between;
}

#Navbar-icon-container {
    margin-top: auto;
    width: 100%;
    position: absolute;
    bottom: 20px;
}

#Navbar-icon-ul {
    list-style-type: none !important;
    margin-top: auto;
    display: flex;
    padding: 0px 39px;
    align-items: center;
    justify-content: space-between;
}

#Navbar-menu-icon {
    width: fit-content;
    align-self: center;
    margin-right: 30px;
    position: absolute;
    top: 46px;
    right: 2px;
}

#Navbar-menu-open {
    height: calc(100vh - 80px);
}

#Navbar-menu-open {
    height: 100vh;
    padding-top: 40px;
}

#NavBar-menu-social {
    list-style-type: none !important;
    padding-inline-start: 0px !important;
}

#NavBar-container {

    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: top 0.3s;
    background: linear-gradient(to bottom, #161535, transparent);
}

#Navbar-mobile-li {
    text-align: start;
    margin-bottom: 20px;
    padding-left: 20px;
}

.active-mobile {
    text-align: start;
    color: #00c3ff !important;
    cursor: pointer;
    margin: 0px 20px;
    font-family: "DINosaur Med", "CheyenneMed", sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-decoration: none !important;
}

.normal-mobile {
    color: #FFFFFF;
    cursor: pointer;
    margin-left: 20px;
    font-family: "DINosaur Med", "CheyenneMed", sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-decoration: none !important;
}

.normal-mobile:hover {
    color: #00c3ff !important;
}

#NavBar-container-scroll {
    top: -20px;
}

#NavBar-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-flow: row-reverse;
    align-self: flex-end
}

.Navbar-link:hover {
    color: #00c3ff !important;
    text-decoration: none !important;
}

ul#NavBar-social {
    flex-grow: 2;
    text-align: right;
    align-items: center;
    margin: 0;
}

ul#NavBar-social li {
    margin-left: 0px;
    display: inline-block;
    margin-right: 30px;

}

ul#NavBar-social li:last-child a {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    color: #fff;
    box-shadow: 0 0 10px transparent;
    transition: box-shadow .4s;
    min-width: 120px;
    position: relative;
    background: linear-gradient(to right, #5100ff 0%, #ff0063 123%);
    border-radius: 22px;
}

ul#NavBar-social li:last-child {
    margin: 0;
}

.active {
    color: #00c3ff !important;
    cursor: pointer !important;
    font-family: "DINosaur Med", "CheyenneMed", sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-decoration: none !important;
}

.normal {
    color: #FFFFFF;
    cursor: pointer !important;
    font-family: "DINosaur Med", "CheyenneMed", sans-serif;
    font-size: 14px;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1px;
    text-decoration: none !important;
}

.normal:hover {
    color: #00c3ff !important;
}

#NavBar-social li:last-child a.normal:hover {
    /* Reset the hover effect for the last item */
    color: inherit !important;
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    #NavBar-wrapper {
        padding: 0 20px;
    }
}