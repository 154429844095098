#pending_box {
    width: 200px;
    margin: 26px auto;
    border-radius: 2px;
    font-family: "DINosaur Bk", "CheyenneRegular", sans-serif;
    font-size: 14px;
    letter-spacing: 1.4px;
    color: #d3d3ef;
    padding-bottom: 6px;
}

#linear_progress {
    background-color: #4f4f72;
    color: rgb(0, 195, 255);
    margin-top: 15px;
    border-radius: 1.5px;
}