.App {
  text-align: center;
  background-color: #20214d;
  color: white;
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  min-height: 100vh;
  flex-direction: column;
  display: flex;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#content {
  flex: 1;
}

footer {

  position: relative;
  bottom: 0;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #20214d;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex: 1;

}

.App-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #20214d;
}

#Background {
  height: 440px;
  background-image: linear-gradient(to bottom, #211b40, #1b183d 51%, #20214d) !important;
}

.App-link {
  color: #61dafb;
}

* {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

*::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.data_card {
  padding: 20px;
  width: auto;
  border-radius: 6px;
  background-image: linear-gradient(to bottom, rgb(104 105 152 / 30%), rgb(55 56 95 / 30%))
}

.data_card_header {
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  font-size: 24px;
  font-weight: 500;
  display: flex;
}

#data_card_mystake_data {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.data_card_grid_cell {
  text-align: initial;
}

.cell_header {
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
}

.cell_padding {
  padding-top: 14px;

}

.card_header_title {
  width: 100%;
  text-align: initial;
  margin-top: 0px;
  height: 52px;
  padding-top: 5px;
  text-indent: 20px;
}

.header_stripe {
  width: 47px !important;
  margin-top: 16px;
  margin-bottom: 2px;
}

.cell_data {
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  font-size: 20px;
  font-weight: 500;
  line-height: 2;
  margin: 0px;
  letter-spacing: normal;
}

.cell_important {
  font-family: "DINosaur Bk", "CheyenneRegular" sans-serif, !important;
  font-size: 16px;
  font-weight: 500;

}

.color_white {
  color: #fff;
}

.color_bone {
  color: #d3d3ef;

}

#error_message {
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  font-size: 15px;
  color: #ff7442 !important;

  margin-top: -30px;
  margin-bottom: 30px;
}
#error_test {
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  font-size: 15px;
  color: #ff7442 !important;

}
@media only screen and (max-width: 840px) {
  .data_card_header {
    font-size: 20px;
  }

  .cell_data {
    font-size: 16px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  padding: 0px;
  height: 33px;
  /* -webkit-text-fill-color: #00c3ff !important; */
  /* color: #fff !important; */
  text-align: end;
}

.StakeInputDisabled {
  -webkit-text-fill-color: #fff !important;
  opacity: 0.5
}

img.shadow {
  -webkit-filter: drop-shadow(0 4px 6px rgba(27, 25, 63, 0.4));
  filter: drop-shadow(0 4px 6px rgba(27, 25, 63, 0.4));
  /* Similar syntax to box-shadow */
}