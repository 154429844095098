#modalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    width: auto;
    box-shadow: 24;
    color: var(--main-white-color);
    background: linear-gradient(to right, #07F6FE, #119DF3);
    padding: 16px;
    overflow: scroll;
}

#rewardModalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    width: -webkit-fill-available;
    box-shadow: 24;
    color: var(--main-white-color);
    background-image: linear-gradient(to bottom, rgb(104 105 152), rgb(55 56 95));
    padding: 16px;
    overflow: scroll;
    min-width: calc(100% - 100px);
    max-height: calc(100% - 100px);
}

.validityBlock {
    overflow: scroll;


}

.validityBlock p {
    font-size: 14px;
    color: white;
    margin-right: auto;
    margin-left: auto;

}

.comingSoonBlock p {
    font-size: 24px;
    color: white;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

}

.greenBlock {
    margin-top: 12px;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
}

.greenText {
    display: flex;
    align-items: center;
    text-align: center;
}

.greenText p {
    font-size: 14px;
    color: #005658;
    margin-right: auto;
    margin-left: auto;
}

.closeText {
    display: flex;
    align-items: center;
}

.closeText p {
    font-size: 16px;
    color: #fff;
    margin-right: auto;
    margin-left: auto;
}

@media only screen and (max-width: 600px) {
    #modalContainer {
        width: 80%;
    }
}