.staking {
  margin: -240px 0 80px;
  width: 100%;
}

.header-text {
  margin-bottom: 20px;
}

#guide_btn {
  background-color: transparent;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #067dfb;
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.staking .header-text p {
  max-width: 747px;
  margin: auto;
  line-height: 2;
  font-family: "DINosaur Bk", "CheyenneRegular", sans-serif !important;
  font-size: 20px;
  color: #d3d3ef;
  line-height: 1.2;
  font-weight: normal;
}

.stake_card {
  max-width: 1140px;
  margin: auto;
  border-radius: 6px;
  background-image: linear-gradient(to bottom, rgb(104 105 152 / 30%), rgb(55 56 95 / 30%));
}

.staking .header-text h1 {
  font-family: "DINosaur Bk", "CheyenneRegular", sans-serif !important;
  color: #fff;
  font-size: 40px;
  font-weight: normal;
  margin-bottom: 25px;
}


#available_balance_header {
  font-size: 16px;
  color: #fff;
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  margin-top: 20px;
  margin-bottom: 0px;
}

#amount_header {
  font-size: 24px;
  color: #fff;
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  padding-top: 20px;
  margin-bottom: 0px;
}

#min_amount_text {
  font-size: 16px;
  color: #d3d3ef;
  font-family: "DINosaur Bk", "CheyenneRegular", sans-serif !important;
  padding-top: 0px;
  margin-top: 0px;
}

#available_balance {
  font-size: 20px;
  color: #d3d3ef;
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 2;
}

#card_data_section {
  max-width: 1140px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  grid-gap: 20px;
  margin: auto;
}

#reward_data_section {
  max-width: 1140px;
  margin: 20px auto;
}

#reward_data_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.reward-item {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
}

.label {
  font-weight: bold;
  text-align: left;
  margin: 5px;
  font-size: 18px;

}

.value {
  text-align: left;
  margin: 5px;
  font-size: 18px;

}

.reward_grid_cell {
  text-align: center;
}

#boost_window_title {
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  margin-top: 32px;
  margin-bottom: 0px;
}

#boost_window {
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  font-size: 20px;
  font-weight: 500;
  color: #d3d3ef;
  height: 40px;
  line-height: 2;
  margin-top: 0px;
  margin-bottom: 20px;
}

.stake_data_cell {
  padding-bottom: 20px;
  padding-top: 0px;
}

#stake_data_section {
  max-width: 1140px;
  /* background-image: linear-gradient(to right, #5100ff -13%, #ff0063 168%); */
  /* padding: 33px 84px 25px 100px; */
  border-radius: 6px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
}

#important_section {
  /* padding: 19px 38px 19px 39px; */
  border-radius: 4px;
  background-image: linear-gradient(to bottom, rgb(104 105 152 / 30%), rgb(55 56 95 / 30%));
  max-width: 1140px;
  margin: auto;
}

#important_cells {
  padding-top: 14px;
  padding-bottom: 19px;
}

#stake_button_section {
  margin: 40px;
}

#warning_section {
  border-radius: 4px;
  background-image: linear-gradient(to right, #5100ff -13%, #ff0063 168%);
  max-width: 1140px;
  margin: 20px auto;
  padding-top: 15px;
  padding-bottom: 5px;
}

#warning_text {
  font-size: 16px;
  padding: 0px 40px 20px 40px;
  margin: 0px;

}

#stake_wallet_text {
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

#Button-stake {
  font-size: 18px;
  font-weight: bold;
  line-height: 0.86;
  text-align: center;
  color: #fff;
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
}

#stake_warning_container {
  max-width: 1120px;
  font-size: 16px;
  padding: 20px;
  border-radius: 6px;
  margin: auto;
  background: linear-gradient(to right, #07F6FE, #119DF3);
}

.stake_warning_container_li {
  text-align: justify;
}

.important_cell_div {
  display: block;
  padding: 0px 24px;
  line-height: 16px;
}

.be_warning_text {
  max-width: 1140px;
  border-radius: 6px;
  padding: 20px;
  background: linear-gradient(to right, #5100ff 0%, #ff0063 123%) !important;
}

.data_card_global_data {
  margin-left: 0px;
}

#boost_time_container {
  display: flex;
  margin: auto;
  width: fit-content;
}


.wallet_section {
  margin-bottom: 40px;
}

.faq_section {
  margin: 20px 0px;
}

@media only screen and (max-width: 1160px) {
  #card_data_section {
    margin: 0px 20px;
  }

  #reward_data_section {
    margin: 20px;
  }

  #important_section {
    margin: 0px 20px !important;
  }

  #stake_warning_container {
    margin: 0px 20px;

  }

  #reward_data_container {
    grid-template-columns: 1fr 1fr;
  }

  #stake_section {
    margin: 0px 20px;
  }
}

@media only screen and (max-width: 600px) {
  #card_data_section {
    display: block;
    margin: auto;
  }

  #reward_data_section {
    margin: 0px;
  }

  #important_section {
    margin: 0px 10px !important;
  }

  #reward_data_container {
    display: block;
  }

  #stake_warning_container {
    margin: 0px;
    padding: 10px 20px;
  }

  .data_card {
    padding: 20px;
    width: auto;
    border-radius: 6px;
    margin-bottom: 20px;
  }

  .staking {
    margin: -292px auto 50px auto;
    width: calc((100%) - 66px);
  }

  .staking .header-text h1 {
    font-size: 30px;
    padding: 0px;
    margin: 20px 0px;
  }

  .staking .header-text p {
    font-size: 18px;
    line-height: 1.11;
    margin-top: 26px;
  }

  .header-text {
    margin-bottom: 40px;
  }

  #stake_data_section {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    margin: auto;
    padding: 20px;
    grid-gap: 20px;
  }

  .stake_data_cell {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #important_cells {
    padding: 20px;
  }

  .important_cell_div {
    display: block;
    padding: 0px;
    line-height: 24px;
  }

  #stake_button_section {
    margin: 20px 0px 40px 0px;
  }

  #stake_wallet_text {
    font-size: 20px;

  }

  .staking .header-text p {
    margin-top: 10px;
  }
}

.status_badge {
  display: inline-block;
  margin-left: 10px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.eligible {
  background-color: #28a745;
}

.pending {
  background-color: #ffc107;
  color: #333;
}

.link_style {
  color: #408be7;
  text-decoration: none;
  font-weight: bold;
}

.link_style:hover {
  color: #0056b3;
}